import * as React from 'react'
import Grid from '@mui/material/Grid';
import PageContainer from '../components/PageContainer';
import Typography from '@mui/material/Typography';

const NotFoundPage = () => (
  <PageContainer>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          404
        </Typography>
        <h3>The page you are looking for was not found</h3>
      </Grid>
    </Grid>
  </PageContainer>
)

export default NotFoundPage