import * as React from 'react'

import Container from '@mui/material/Container';


const PageContainer = ({ children, sx = [], ...props }) => {
    return (
        <Container maxWidth="lg" sx={[{ marginTop: 4 }, ...(Array.isArray(sx) ? sx : [sx])]} {...props}>
            {children}
        </Container>
    )
}


export default PageContainer